<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = defineProps<{
  heading?: string;
  categories: Array<Schemas["Category"]>;
}>();
const { heading, categories } = toRefs(props);
</script>

<template>
  <div>
    <SharedHSlider
      v-if="categories.length > 0"
      :title="heading || $t('category.top-categories')"
    >
      <ProductCategoryCard
        v-for="category in categories"
        :key="category.id"
        class="flex-shrink-0 mt-5 w-[200px]"
        :category="category"
      />
    </SharedHSlider>
  </div>
</template>
